<template>
  <div class="card politica-de-negociacao-form">
    <div class="font-medium text-3xl text-900 mb-3">
      <span class="text-primary text-5xl">/</span> {{ title }}
    </div>
    <div class="p-fluid w-full">
      <div class="text-500 mb-5">Preencha os campos abaixo</div>
      <Toast />
    </div>
    <div class="politica-de-negociacao-form__field">
      <label for="name">Descrição</label>
      <InputText
        id="name"
        maxlength="100"
        v-model.trim="form.descricao"
        required="true"
        autofocus
        autocomplete="off"
        :class="{ 'p-invalid': submitted && !form.descricao }"
      />
      <small class="p-error" v-if="submitted && !form.descricao"
        >Nome é obrigatório.</small
      >
    </div>
    <div class="politica-de-negociacao-form__field">
      <label for="periodo-de-vigencia">Período de vigência</label>

      <Calendar
        dateFormat="dd/mm/yy"
        showIcon="true"
        :manualInput="false"
        id="periodo-de-vigencia"
        v-model="form.periodoVigencia"
        :minDate="minDatePeriodoDeVigencia"
        autocomplete="off"
      />
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="name">Valor percentual da multa (%)</label>
      <InputText
        id="name"
        v-model.trim="form.valorPercentualMulta"
        required="true"
        autofocus
        autocomplete="off"
        :class="{ 'p-invalid': submitted && !form.valorPercentualMulta }"
      />
      <small class="p-error" v-if="submitted && !form.valorPercentualMulta"
        >Valor percentual da multa é obrigatório.</small
      >
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="name">Valor percentual dos juros (%)</label>
      <InputText
        id="name"
        v-model.trim="form.valorPercentualJuro"
        required="true"
        autofocus
        autocomplete="off"
        :class="{ 'p-invalid': submitted && !form.valorPercentualJuro }"
      />
      <small class="p-error" v-if="submitted && !form.valorPercentualJuro"
        >Valor percentual dos juros é obrigatório.</small
      >
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="name">Valor percentual dos honorários (%)</label>
      <InputText
        id="name"
        v-model.trim="form.valorPercentualHonorario"
        required="true"
        autofocus
        autocomplete="off"
        :class="{ 'p-invalid': submitted && !form.valorPercentualHonorario }"
      />
      <small class="p-error" v-if="submitted && !form.valorPercentualHonorario"
        >Valor percentual dos honorários é obrigatório.</small
      >
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="payment_method">Modo de pagamento da 1ª parcela</label>
      <Dropdown
        v-model="form.modoPagamentoPrimeiraParcela"
        :options="listaOpcoesPrimeiraParcela"
        optionLabel="name"
        optionValue="code"
        id="payment_method "
        placeholder="Selecione o modo de pagamento"
      />
    </div>

    <div
      class="politica-de-negociacao-form__field"
      v-if="form.modoPagamentoPrimeiraParcela === 'BY_PERCENTAGE'"
    >
      <label for="name">Valor em percentual</label>
      <InputText
        id="name"
        v-model.trim="form.percentualMinimo"
        required="true"
        autofocus
        autocomplete="off"
        :class="{ 'p-invalid': submitted && !form.percentualMinimo }"
      />
      <small class="p-error" v-if="submitted && !form.percentualMinimo"
        >Campo obrigatório.</small
      >
    </div>
    <div
      class="politica-de-negociacao-form__field"
      v-if="form.modoPagamentoPrimeiraParcela === 'BY_VALUE'"
    >
      <label for="name">Valor</label>
      <InputText
        id="name"
        v-model.trim="form.valorMinimo"
        required="true"
        autofocus
        maxlength="14"
        v-money="money"
        autocomplete="off"
        :class="{ 'p-invalid': submitted && !form.valorMinimo }"
      />
      <small class="p-error" v-if="submitted && !form.valorMinimo"
        >Campo obrigatório.</small
      >
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="atualizacao_divida">Tipo de atualização da dívida</label>
      <Dropdown
        v-model="form.tipoAtualizacaoDivida"
        :options="listaAtualizacaoDaDivida"
        optionLabel="name"
        optionValue="code"
        id="atualizacao_divida "
        placeholder="Selecione o tipo de atualização da dívida"
      />
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="evidencia_divida">Data de evidência da dívida</label>
      <Calendar
        dateFormat="dd/mm/yy"
        :showIcon="true"
        :manualInput="false"
        id="evidencia_divida"
        v-model="form.dataEvidenciaDivida"
        :minDate="minDateDeEvidenciaDaDivida"
        placeholder="Data de evidência da dívida"
        autocomplete="off"
      />
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="atualizacao_divida">Tipo de acordo</label>
      <Dropdown
        v-model="form.tipoAcordo"
        :options="listaAtualizacaoDaDivida"
        optionLabel="name"
        optionValue="code"
        id="atualizacao_divida "
        placeholder="Selecione o Tipo de acordo"
      />
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="name">Prazo máximo para fechar acordo</label>
      <InputText
        id="name"
        v-model.trim="form.prazoMaximoParaFecharAcordo"
        required="true"
        autofocus
        autocomplete="off"
        :class="{ 'p-invalid': submitted && !form.prazoMaximoParaFecharAcordo }"
      />
      <small
        class="p-error"
        v-if="submitted && !form.prazoMaximoParaFecharAcordo"
        >Prazo máximo para fechar acordo é obrigatório.</small
      >
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="tipo_prazo">Tipo de prazo</label>
      <Dropdown
        v-model="form.tipoPrazo"
        :options="listaTipoDePrazo"
        optionLabel="name"
        optionValue="code"
        id="tipo_prazo "
        placeholder="Selecione o Tipo de prazo"
      />
    </div>

    <div class="politica-de-negociacao-form__field">
      <label for="taxas_bancarias">Tipo de pagamento de taxas bancárias</label>
      <Dropdown
        v-model="form.tipoPagamentoTaxaBancaria"
        :options="listaPagamentoDeTaxasBancarias"
        optionLabel="name"
        optionValue="code"
        id="taxas_bancarias "
        placeholder="Selecione o Tipo de pagamento de taxas bancárias"
      />
    </div>

    <div class="politica-de-negociacao-form__actions flex mt-5">
      <Button
        label="Salvar"
        icon="pi pi-check"
        class="p-button-primary w-6"
        @click="save"
      />
      <Button
        label="Voltar"
        icon="pi pi-times"
        class="p-button-text w-3 ml-auto"
        @click="$router.push('/politica-de-negociacao')"
      />
    </div>
  </div>
</template>

<script>
import { VMoney } from 'v-money'
import politicaDeNegociacaoService from '../services/politica-de-negociacao.service'
import { PoliticaDeNegociacao } from '../entities/politica-de-negociacao'

export default {
  data() {
    return {
      form: new PoliticaDeNegociacao(),
      submitted: false,
      minDatePeriodoDeVigencia: new Date('2000-01-01'),
      minDateDeEvidenciaDaDivida: new Date('2000-01-01'),
      roles: [
        { label: 'Administrador', value: 'ADMIN' },
        { label: 'Usuário', value: 'USER' }
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2
      }
    }
  },
  directives: { money: VMoney },

  computed: {
    title() {
      return this.$route.params.id
        ? 'Editar Política de negociação'
        : 'Adicionar Política de negociação'
    },
    listaOpcoesPrimeiraParcela() {
      return [
        {
          code: 1, // validar com o backend
          name: 'Por valor'
        },
        {
          code: 2, // validar com o backend
          name: 'Percentual mínimo'
        }
      ]
    },
    listaAtualizacaoDaDivida() {
      return [
        {
          code: 1, // validar com o backend
          name: 'Pela data do acordo'
        },
        {
          code: 2, // validar com o backend
          name: 'Pela data atual'
        }
      ]
    },
    listaTipoDeAcordo() {
      return [
        {
          code: 1, // validar com o backend
          name: 'Por parcela'
        },
        {
          code: 2, // validar com o backend
          name: 'Por contrato'
        },
        {
          code: 3, // validar com o backend
          name: 'Por devedor'
        }
      ]
    },
    listaTipoDePrazo() {
      return [
        {
          code: 1, // validar com o backend
          name: 'Dias corridos'
        },
        {
          code: 2, // validar com o backend
          name: 'Dias úteis'
        }
      ]
    },
    listaPagamentoDeTaxasBancarias() {
      return [
        {
          code: 1, // validar com o backend
          name: 'Proprietário'
        },
        {
          code: 2, // validar com o backend
          name: 'Devedor'
        }
      ]
    }
  },
  methods: {
    async save() {
      this.submitted = true
      try {
        console.log(this.form)
        await politicaDeNegociacaoService.save(this.form)
        this.$toast.add({
          severity: 'success',
          summary: 'Usuário gravado com sucesso!',
          life: 3000
        })
        this.$router.replace('')
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Problemas ao armazenar o usuário!',
          life: 3000
        })
        console.log(err)
      }
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      const { data } = await politicaDeNegociacaoService.findById(
        this.$route.params.id
      )

      this.form = new PoliticaDeNegociacao(data)
    }
  }
}
</script>

<style scoped lang="sass">
.politica-de-negociacao-form
  display: flex
  flex-wrap: wrap
  &__field
    display: flex
    flex-direction: column
    margin: 10px
    width: 30%
    &__actions
      display: flex
      width: 300px
</style>
