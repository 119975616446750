export class PoliticaDeNegociacao {
  constructor(data = {}) {
    this.id = data.id;
    this.descricao = data.descricao;
    this.periodoVigencia = data.periodoVigencia;
    this.valorPercentualMulta = data.valorPercentualMulta || 2;
    this.valorPercentualJuro = data.valorPercentualJuro || 2;
    this.valorPercentualHonorario = data.valorPercentualHonorario || 10;
    this.modoPagamentoPrimeiraParcela =
      data.modoPagamentoPrimeiraParcela;
    this.valorMinimo = data.valorMinimo;
    this.percentualMinimo = data.percentualMinimo;
    this.tipoAtualizacaoDivida = data.tipoAtualizacaoDivida;
    this.dataEvidenciaDivida = data.dataEvidenciaDivida;
    this.tipoAcordo = data.tipoAcordo;
    this.prazoMaximoParaFecharAcordo = data.prazoMaximoParaFecharAcordo || 1;
    this.tipoPrazo = data.tipoPrazo;
    this.tipoPagamentoTaxaBancaria =
      data.tipoPagamentoTaxaBancaria;
  }
}

// const ModoPagamento = {
//   POR_VALOR: 1,
//   PERCENTUAL_MINIMO: 2
// }
