import ClienteService from "../../../services/crud";

class PoliticaDeNegociacaoService {
  constructor() {
    this.httpService = new ClienteService("/admin/politica-negociacao");
  }

  getAllPolitics(params) {
    return this.httpService.findAll(params);
  }

  findById(id) {
    return this.httpService.findById(id);
  }

  save(politica) {
    return this.httpService.save(politica);
  }

  delete(politicaId) {
    if (!politicaId) {
      throw new Error("É necessário informar o ID do registro para deletar");
    }
    return this.httpService.remove(politicaId);
  }
}

export default new PoliticaDeNegociacaoService();
